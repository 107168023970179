.Header {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 1em;
  padding-top: 1rem;
  padding-bottom: 1em;
  .header-contents{
    max-width: 1040px;
    margin: auto;
    .logo {
      height: 35px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
