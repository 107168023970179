$deep-sapphire: #00005b;
$axa-blue: #00008f;
$axa-blue-dark: #000072;
$axa-red: #ff1721;
$ocean-blue: #4976ba;
$myaxa-bg-blue: rgb(233, 236, 245);
$burnt-sienna: #f07662;
$dark: #333;
$muted: #999;
$indigo: #494df4;
$gray: #7f7f7f;
$dusty-gray: #999999;
$mercury: #e5e5e5;
$wild-sand: #f5f5f5;
$alabaster: #fafafa;
$white: #ffffff;
$shy-tomato: #c91432;
$customer-dedicated-screen: #aeea00;
$cotton-candy: #fad6de;
$malachite: #1cc54e;
$azure: #3032c1;
$silver: #cccccc;
$azalea: #e196aa;
$dark-red: #a94442;
