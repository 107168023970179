.check-ng-modal {
  p {
    margin-bottom: 20px;
  }

  .confirmation-buttons {
    text-align: center;
    border: solid 1px #bbbbbb;
    border-radius: 5px;
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    background:linear-gradient(#ffffff,#dddddd);

    .btn:not(:first-child) {
      margin-left: 10px;
    }
  }
  .modal-dialog-centered {
    max-width: 500px;
    margin: 0 auto;
  }
}
