
$color-green: #00FF00;
$color-blue: rgb(138, 222, 235);
$color-gray: #999898;
$color-disable: #777;

a.AxaButton, .AxaButton {
   padding: 0.75em 3em;
   border: 2px solid $color-blue;
   background-color: $color-blue;
   cursor: pointer;
   color: #000;
   fill: #fff;
   text-decoration: none;
   opacity: 1;
  background-image: -webkit-linear-gradient(top, #fff 0%, $color-blue 100%);
  background-image: -o-linear-gradient(top, #fff 0%, $color-blue 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to($color-blue));
  border-radius: 4px;

  &:hover {
    background-color: $color-blue;
    border-color: $color-blue;
  }

  &.disabled {
    pointer-events: none;
    cursor: none;
    border: 2px solid $color-disable;
    background-color: #777;
    background-image: -webkit-linear-gradient(top, #fff 0%, $color-disable 100%);
    background-image: -o-linear-gradient(top, #fff 0%, $color-disable 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to($color-disable));
  }
}

a.AxaButton2, .AxaButton2 {
  padding: 0.75em 3em;
  border: 2px solid $color-gray;
  background-color: $color-gray;
  cursor: pointer;
  color: #000;
  fill: #fff;
  text-decoration: none;
  opacity: 1;
 background-image: -webkit-linear-gradient(top, #fff 0%, $color-gray 100%);
 background-image: -o-linear-gradient(top, #fff 0%, $color-gray 100%);
 background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to($color-gray));
 border-radius: 4px;

 &:hover {
   background-color: $color-gray;
   border-color: $color-gray;
 }

 &.disabled {
   pointer-events: none;
   cursor: none;
   border: 2px solid $color-disable;
   background-color: transparent;
   background-image: -webkit-linear-gradient(top, #fff 0%, $color-disable 100%);
   background-image: -o-linear-gradient(top, #fff 0%, $color-disable 100%);
   background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to($color-disable));
 }
}

.AxaButton3 {
  padding: 0.75em 3em;
  border: 2px solid #00008f;
  background-color: #00008f;
  cursor: pointer;
  color: #fff;
  fill: #fff;
  text-decoration: none;
  opacity: 1;

  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #00005b;
    border-color: #00005b;
  }

  &:focus {
    color: #fff;
    text-decoration: none;
  }

  &.disabled {
    pointer-events: none;
    cursor: none;
    border: 2px solid #777;
    background-color: #777;
  }

  // Theme definitions
  &.ghost {
    background-color: white !important;
    color: #00008f;
  }

  &.danger {
    background-color: #ec4d33;
    border-color: #b51900;
    color: #fff;

    &:hover {
      background-color: #b51900;
    }
  }

  &.compass {
    background-color: #4ddf48;
    border: 1px solid #2fdf16;
    color:black;
    background-image: linear-gradient(rgb(204,255,153), rgb(82,179,6));
    &:hover {
      background-color: #2fdf16;
    }
  }
}

.small {
  padding: 0.5em 1.1em !important;
}
