@import './assets/stylesheets/_variables';

.App {
  margin-bottom: 1rem;
  font-family: 'メイリオ',Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic',verdana,'Hiragino Kaku Gothic ProN' ,sans-serif;

  .compass-layout.row {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
  .loading-content {
    opacity: 0.3;
  }

.container {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.pagefooter {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  background-color: $white;
}

.text-center {
  text-align: center !important;
}

.display-block {
  display: block;
}

.text-pre {
  word-break: break-all; /* IE 5.5-7 */
  white-space: pre-wrap; /* Modern browsers */
}

.bg-error {
  background-color: $cotton-candy;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 0;

  a + a {
    margin-left: 15px;
  }
}

.ma-auto {
  margin: auto;
}

.pb-15 {
  padding-bottom: 15px;
}

.modal-backdrop {
  &.in {
    opacity: 0.5;
  }
}

// Screen Sizes
$screen-phone-max: 767px;
$screen-tablet-min: 768px;
$screen-tablet-max: 1027px;
$screen-desktop-min: 1028px;

// helper mixins for screen sizes
@mixin on-phone {
  @media screen and (max-width: $screen-tablet-min) {
    @content
  }
};
@mixin on-phone-or-tablet {
  @media screen and (max-width: $screen-tablet-max) {
    @content
  }
};
@mixin on-tablet-or-desktop {
  @media screen and (min-width: $screen-tablet-min) {
    @content
  }
};
@mixin on-desktop {
  @media screen and (min-width: $screen-desktop-min) {
    @content
  }
};


$deep-sapphire: #00005b;
$axa-blue: #00008f;
$indigo: #494df4;
$gray: #7f7f7f;
$dusty-gray: #999999;
$mercury: #e5e5e5;
$wild-sand: #f5f5f5;
$alabaster: #fafafa;
$white: #ffffff;
$shy-tomato: #c91432;
