body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

#main {
  width: 100%;
  height: 100%;
  padding: 5px 5px 0px 5px;
  box-sizing: border-box;
  overflow: hidden;
}

#content {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#content_title {
  display: block;
  height: 45px;
  margin: 0;
  padding: 7px 0 0 20px;
  background: #f9f9f9;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

#content_header {
  position: relative;
  max-height: 81px;
  z-index: 10;
}

#content_header #content_title h2 {
  display: inline-block;
  height: 31px;
  color: #111111;
  margin: 0;
  padding: 0 0 0 10px;
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
  text-shadow: rgba(255, 255, 255, 0.4) 0 1px 0;
}

.inhouse #content_title {
  background: #D3E5F8;
  border: 1px solid #516CB5;
}

.inhouse #content_header #content_title h2 {
  border-left: 5px #003188 solid;
}

.emp #content_title:after {
  display: inline-block;
  position: absolute;
  margin: 0 0 0 5px;
  padding: 2px 3px;
  color: #ffffff;
  font-size: 12px;
  line-height: 1.2;
  text-shadow: rgba(0, 0, 0, 0.4) 0 -1px 0;
  background: #ff1821;
  top: 2px;
  right: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

#content #content_header h3 {
  display: inline-block;
  height: 31px;
  color: #081c4a;
  margin: 0 0 0 10px;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  vertical-align: top;
  text-shadow: rgba(255, 255, 255, 0.4) 0 1px 0;
  border: none;
  background: none;
}

#content #content_header h3.double {
  line-height: 1.2;
}

#content #content_header h3.double2 {
  line-height: 1.6;
  color: #FF0000;
  margin-left: 0px;
  font-size: 11px;
  height: 15px;
}

.detail-area {
  min-height: 20px;
  padding: 19px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  margin: 5px;
}

.white-area {
  background-color: #fff;
}

.surrender-area {
  padding: 0 0 0 15px;
  border: 3px solid #b3afaf;
  margin-top: 5px;
}

.surrender-title {
  min-height: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.button-align {
  display: flex;
}

.button-align div:last-child {
  margin-left: auto;
  margin-right: 0px;
}

.notice {
  margin: 12px 16px 6px 21px;
  color: #ff1821;
  font-size: 1.3em;
}

.control-fonts {
  font-size: 1.3em;
}
